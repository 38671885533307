<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <div class="ic-title">录入机构</div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="机构类型" name="typeOfInstitution">
            <a-radio-group v-model:value="form.typeOfInstitution">
              <a-radio :value="false">留学机构</a-radio>
              <a-radio :value="true">国际学校</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="合同类型" name="typeOfContract">
            <a-radio-group v-model:value="form.typeOfContract">
              <a-radio :value="false">纸质合同</a-radio>
              <a-radio :value="true">电子合同</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="公司主体" name="companySubject"
            :rules="[{ required: true, message: '请输入公司主体' }, { min: 1, max: 50, message: '只能输入1-50个字符' }]">
            <a-input @blur="handleComBlur($event, blurconfig, form.companySubject)" allow-clear
              v-model:value.trim="form.companySubject" placeholder="输入公司主体">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="签约时间" name="signingTime" :rules="[{ required: true, message: '请选择签约时间' }]">
            <a-date-picker :locale="locale" v-model:value="form.signingTime" placeholder="请选择签约时间" style="width: 100%"
              :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="机构名" name="name"
            :rules="[{ required: true, message: '请输入机构名' }, { min: 1, max: 50, message: '只能输入1-50个字符' }]">
            <a-input @blur="handleBlur($event, blurInsconfig, form.name)" allow-clear v-model:value.trim="form.name"
              placeholder="输入机构名">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系人" name="contact"
            :rules="[{ required: true, message: '请输入联系人' }, { min: 1, max: 50, message: '只能输入1-50个字符' }]">
            <a-input allow-clear v-model:value.trim="form.contact" placeholder="输入联系人">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="手机号" name="phoneNumber"
            :rules="[{ required: true, message: '请输入手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
            <a-input allow-clear v-model:value.trim="form.phoneNumber" placeholder="手机号">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="省市区" name="provinceCityDistrict" :rules="[{ required: true, message: '请选择省市区' }]">
            <a-cascader v-model:value="form.provinceCityDistrict" :show-search="{ filter }" :change-on-select="false"
              :options="citys" placeholder="全部" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="详情地址" name="detailedAddress"
            :rules="[{ required: true, message: '请输入详情地址' }, { min: 1, max: 50, message: '只能输入1-50个字符' }]">
            <a-input allow-clear v-model:value.trim="form.detailedAddress" placeholder="输入详情地址">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="业务类型" name="businessType" :rules="[{ required: true, message: '请选择业务类型' }]">
            <a-select v-model:value="form.businessType" placeholder="请选择业务类型" mode="multiple" allow-clear
              style="width: 100%" show-search :filter-option="filterOption" @change="getSelect($event, 3)">
              <a-select-option v-for="option in companyEntity" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="申请国家/地区" name="countryOfApplication" :rules="[{ required: true, message: '请选择申请国家/地区' }]">
            <a-select v-model:value="form.countryOfApplication" placeholder="请选择申请国家" mode="multiple" allow-clear
              style="width: 100%" show-search :filter-option="filterOption">
              <a-select-option v-for="option in country" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="产品最低价格(人民币)" name="minPrice" :rules="[{ required: true, message: '请输入产品最低价格' }, {
      pattern: /^\+?[1-9][0-9]*$/,
      message: '请输入正整数'
    }]">
            <a-input-number style="width: 100%;" v-model:value="form.minPrice"
              :formatter="value => (value == null || value === '') ? value : `${value}`.replace(/(\.\d{2})\d*$/, '$1')">
              <template #addonAfter>元</template>
            </a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="接待城市" name="receptionCity">
            <a-select v-model:value="form.receptionCity" placeholder="请选择接待城市" mode="multiple" allow-clear
              style="width: 100%" show-search :filter-option="filterOption">
              <a-select-option v-for="option in receptionCity" :key="option" :name="option">{{ option }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="备注" name="remarks" :rules="[{ min: 1, max: 500, message: '只能输入1-500个字符' }]">
            <a-textarea v-model:value="form.remarks" placeholder="请输入备注" style="width: 100%;height: 100px;" />
          </a-form-item>
        </a-col>
      </a-row>
      <div class="ic-title">CRM系统信息</div>
      <a-row>
        <a-col :span="12">
          <a-form-item name="employeePhoneNumber">
            <div class="label-style" slot="label">
              <div>
                <!-- <span class="ls-required">*</span> -->
                <span>系统管理员手机号(登录账号)</span>
              </div>
              <a-popover placement="top">
                <template #content>
                  <p class="ls-content">用户可使用该手机号登录CRM系统，默认密码000000</p>
                </template>
                <question-circle-outlined class="ls-icon" />
              </a-popover>
            </div>
            <a-input :disabled="true" allow-clear v-model:value.trim="form.employeePhoneNumber"
              placeholder="输入系统管理员手机号(登录账号)">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item name="employeeName"
            :rules="[{ required: true, message: '请选择系统管理员姓名' }, { min: 1, max: 50, message: '只能输入1-50个字符' }]">
            <div class="label-style" slot="label">
              <div>
                <span class="ls-required">*</span>
                <span>系统管理员姓名</span>
              </div>
            </div>
            <a-input allow-clear v-model:value.trim="form.employeeName" placeholder="输入系统管理员姓名">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item name="domainName">
            <div class="label-style" slot="label">
              <div>
                <span>专属CRM网址</span>
              </div>
              <a-popover placement="top">
                <template #content>
                  <p class="ls-content">没有专属网址的机构只能在官网登录</p>
                </template>
                <question-circle-outlined class="ls-icon" />
              </a-popover>
            </div>
            <a-input v-model:value.trim="form.domainName" placeholder="输入英文/拼音/简写" addon-before="https://"
              addon-after=".liufucrm.com" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="open" :loading="submitLoading">确认添加</a-button>
      </a-form-item>
    </a-form>
    <a-modal v-model:open="openModal" title="警告">
      <div>
        <p style="padding: 30px 0;">为机构开通专属CRM网址后，机构可通过专属网址或www.liufucrm.com进行登录，专属网址一旦设置成功无法修改，确定吗</p>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <a-button @click="submit" type="primary">好的</a-button>
        </div>
      </template>
    </a-modal>
    <!-- <a-modal v-model:open="showModal" title="提示" @ok="crmOk">
      <div>
        <p style="padding: 30px 0;">专属CRM网址与 账号姓名 添加的 机构名 重复，请更换后再试</p>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <a-button @click="crmOk" type="primary">好的</a-button>
        </div>
      </template>
    </a-modal> -->
    <a-modal v-model:open="openReModal" :title="modalTitle" @ok="handleOk">
      <div v-html="modalContent"></div>
    </a-modal>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { citysMenus } from '@/city.js';
import { companyEntity, country, receptionCity } from '@/utils/public';
import dayjs from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      originalValue: '',
      originalComValue: '',
      locale,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: { crm: "false", typeOfInstitution: false, typeOfContract: false },
      citys: citysMenus,
      companyEntity: companyEntity,
      country: country,
      receptionCity: receptionCity,
      detailLoading: false,
      disabledStatus: false,
      openModal: false,
      showModal: false,
      modalTitle: '',
      modalContent: '',
      blurconfig: {
        title: '提示',
        submitUrl: '/agency/institutions/companySubject',
        key: "companySubject",
        blurMessage: (res) => {
          return `机构与  ${res.activateName} 添加的 ${res.institutionsName}  重复！机构状态为${{
            false: "启用",
            true: "禁用",
          }[res.locked]}，签约时间：${res.signingTime ? res.signingTime.split('T')[0] : ''}`
        },
      },
      blurInsconfig: {
        title: '提示',
        submitUrl: '/agency/institutions/name',
        key: "name",
        blurMessage: (res) => {
          return `机构与  ${res.activateName} 添加的 ${res.institutionsName}  重复！机构状态为${{
            false: "启用",
            true: "禁用",
          }[res.locked]}，签约时间：${res.signingTime ? res.signingTime.split('T')[0] : ''}`
        },
      },
      counting: false,
      countdown: 5,
      countdownInterval: null,
      startDisabled: false,
      isComfirm: false,
      domain: {},
      openReModal: false
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  mounted() {

  },
  methods: {
    async startCountdown() {
      if (!this.counting && !this.isComfirm) {
        this.counting = true;
        this.countdown = 5;
        this.startDisabled = true;
        this.countdownInterval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(this.countdownInterval);
            this.counting = false;
            this.startDisabled = false;
            this.isComfirm = true
          }
        }, 1000);
      } else {
        this.submitLoading = true;
        let signTime = dayjs(this.form.signingTime).format('YYYY-MM-DD HH:mm:ss');
        let { minPrice, typeOfInstitution, typeOfContract, companySubject, name, contact, phoneNumber, provinceCityDistrict, detailedAddress, businessType, countryOfApplication, remarks, crm, receptionCity, username, employeeName, employeePhoneNumber, employeeRemarks } = this.form
        try {
          await this.$http.post('/agency/institutionsRegistration/entry', { id: this.selectedRows[0].id, minPrice, typeOfInstitution, typeOfContract, companySubject, signingTime: signTime, name, contact, phoneNumber, provinceCityDistrict, detailedAddress, businessType, countryOfApplication, receptionCity, remarks, crm, domainName: this.domain.url, username, employeeName, employeePhoneNumber, employeeRemarks });
          this.crmOk()
          this.$message.success('操作成功');
          this.$refs.form.resetFields();
          this.$emit('close');
        } catch ({ message }) {
          this.$message.error(message);
        }
        this.submitLoading = false;
        //--------------------------------------
      }
    },
    copyToClipboard() {
      const contentToCopy = this.$refs.contentToCopy;
      const range = document.createRange();
      range.selectNode(contentToCopy);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      try {
        document.execCommand('copy');
        this.$message.success('内容已成功复制到粘贴板');
      } catch (e) {
        console.error('复制失败', e);
      }
      selection.removeAllRanges();
    },

    crmOk() {
      this.showModal = false
    },
    handleOk() {
      this.openReModal = false;
    },

    handleBlur(e, info, val) {
      if (val !== this.originalValue) {
        this.fetchData(info, val);
      }
    },
    handleComBlur(e, info, val) {
      if (val !== this.originalComValue) {
        this.fetchData(info, val);
      }
    },

    async fetchData(info, val) {
      let { submitUrl, key, title, blurMessage } = info
      let value = val
      try {
        let res = await this.$http.get(submitUrl, { [key]: value });
        if (res.code == '200') {
          this.openReModal = true;
          this.modalTitle = title;
          let message = ''
          if (typeof blurMessage === 'function') {
            message = blurMessage(res);
          }
          this.modalContent = message;
        }
      } catch ({ message }) {
        //this.$message.error(message);
      }
    },
    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/agency/institutionsRegistration/entry/get", { id: id })
        if (res.code == '200') {
          this.form = res.source
          if (typeof res.source.signingTime === 'string') {
            this.form.signingTime = dayjs(res.source.signingTime);
          }
          this.form.countryOfApplication = res.source.countryOfApplication ? res.source.countryOfApplication : undefined
          this.form.receptionCity = res.source.receptionCity ? res.source.receptionCity : undefined
          this.form.crm = res.source.crm ? 'true' : 'false'
          this.originalValue = this.form.name
          this.originalComValue = this.form.companySubject

          this.form.businessType = res.source.businessType ? res.source.businessType.split(',') : [];
          this.form.countryOfApplication = res.source.countryOfApplication ? res.source.countryOfApplication.split(',') : [];
          this.form.receptionCity = res.source.receptionCity ? res.source.receptionCity.split(',') : [];

          this.disabledStatus = true
          if (this.form.domainName) {
            var currentURL = this.form.domainName;
            var subdomain = currentURL.match(/^(?:https?:\/\/)?([^.]+)\./);
            if (subdomain) {
              this.form.domainName = subdomain[1]
            } else {
              this.form.domainName = ''
            }
          }
        }
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    async open() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.openModal = true
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      let { username } = this.form
      
      if (this.disabledStatus || this.form.crm == 'false') {
        this.submitLoading = true;
        let signTime = dayjs(this.form.signingTime).format('YYYY-MM-DD HH:mm:ss');
        let { employeePhoneNumber, employeeName, minPrice, companySubject, name, contact, phoneNumber, provinceCityDistrict, detailedAddress, businessType, countryOfApplication, remarks, receptionCity } = this.form
        let domainName = '';
        if(this.form.domainName) {
          domainName = `https://${this.form.domainName}.liufucrm.com`
        }
        try {
          await this.$http.post('/agency/institutionsRegistration/entry', { id: this.selectedRows[0].id, employeePhoneNumber, minPrice, companySubject, signingTime: signTime, name, contact, phoneNumber, provinceCityDistrict, detailedAddress, businessType, countryOfApplication, remarks, receptionCity, employeeName, domainName: domainName });
          this.$message.success('操作成功');
          this.$refs.form.resetFields();

          this.openModal = false;
          this.$emit('close');
        } catch ({ message }) {
          this.$message.error(message);
        }
        this.submitLoading = false;
      } else {
        this.domain.url = domainName
        this.domain.username = username
        this.showModal = true;
      }
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getSelect(value, num) {
      // if (num && value.length > num) {
      //   value.pop();
      //   this.$message.error(`最多只能选择${num}个`);
      // }
    },
  }
}
</script>

<style scoped lang="scss">
.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}
</style>
